html {
	scroll-behavior: smooth;
}

body {
	margin: 0 auto;
	font-family: $font-body;
	overflow: visible;
	overflow-x: hidden;
}

.centered {
	max-width: $max-width;
	width: 94%;
	margin-left: auto;
	margin-right: auto;
}
