.text-component {
	margin-bottom: 2em;
	overflow: hidden;
	p:first-child {
		margin-top: 0;
	}

	p:last-child {
		margin-bottom: 0;
	}

	.image-align-left,
	.image-align-right {
		margin: 0;

		img {
			@include min(500px) {
				max-width: 42.5%;
			}
		}
	}

	.image-align-left {
		img {
			float: left;
			margin: 0 1em .75em 0;
		}

		>*:first-of-type img {
			clear: both;
		}
	}

	.image-align-right {
		img {
			float: right;
			margin: 0 0 .75em 1em;
		}

		>*:first-of-type img {
			clear: both;
		}
	}

	.image-align-center {
		text-align: center;

		img {
			margin: 0 0 .75em;
			max-width: 100%;
		}
	}

	[contenteditable="true"] {

		.images-container,
		.image {

			&:focus,
			&:hover {
				img {
					box-shadow: 0 0 5px red;
				}
			}
		}
	}
}
