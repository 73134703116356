.additional {
	position: relative;
	max-width: 1920px;
	margin: 0 auto;
	width: 100%;
	.element-type-heading {
		font-size: rem(26px);
		color: $color-dark;
		font-weight: 700;
		margin-bottom: 0;
		padding-bottom: 1em;
	}
	.text-component{
		margin-bottom: 0;
		font-size: rem(17px);
		h3{
			font-size: rem(20px);
		}
	}

	&__content {
		background-color: $color-light;
		color: $color-dark;
		padding: 0rem 0 2.7rem;
		max-width: 1200px;
		border-radius: 1.5rem;
		@include min(600px){
			display: grid;
			gap: 2rem;
			padding: 4.7rem 5% 2.7rem;
			.additional__left{
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 0 4rem;
				.more{
					grid-column: 1 / -1;
					justify-content: flex-start;
				}
			}
		}
		@include min(1024px){
			grid-template-columns: 2.7fr 1.3fr;
			.additional__left{
				+ .additional__section{
					margin-left: auto;
				}
			}
		}
	}

	.official-hours{
		margin-top: 56px;
		b{
			display: inline-block;
			min-width: 2.75em;
			font-weight: 400;
		}
	}
	.more{
		width: 100%;
		@include link(".more__button"){
			color: $color-light;
		}
	}
	.list{
		&__item{
			font-size: rem(17px);
			margin-bottom: 0;
		}
	}

	@include min(600px){
		padding: 2rem 0 5rem;
		&::before{
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
			background: #fff url(/images/local/v-202411080924/additional-bg.jpg) no-repeat center top / cover;
			z-index: -1;
		}
	}
	@include min(1024px){
		padding: 17% 0 5.3rem;
	}
	@include min(1920px){
		padding: 19.3rem 0 5.3rem;
	}

	@include link("a:not(.more__button)"){
		color: $color-light;
		text-decoration: underline;
	}

	@include link-over("a:not(.more__button)") {
		text-decoration: none;
	}
}
