.page-footer{
	text-align: center;
	background-color: $color-brand;
	color: $color-light;
	padding: 3.5em 3rem 3em;
	font-size: rem(14px);
	&__list{
		grid-area: footer-list;
	}
	&__webmaster{
		grid-area: footer-webmaster;
	}
	&__heading{
		grid-area: footer-heading;
		font-size: rem(20px);
		font-weight: 400;
		display: flex;
		align-items: center;
	}
	&__logo{
		margin-right: 1.5rem;
	}
	@include min(600px){
		.centered {
			display: grid;
			grid-template:
					"footer-heading footer-webmaster"
					"footer-list footer-webmaster";
			gap: 1rem;
		}
		&__list{
			text-align: left;
		}
		&__webmaster{
			text-align: right;
		}
	}
	@include link {
		color: $color-light;
	}
}
