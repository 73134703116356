.header {
	position: relative;
	background: $color-brand;
	color: $color-light;
	padding: .65rem 0 2rem;
	@include link(){
		color: $color-light;
	}
	@include min(600px){
		position: absolute;
		left: 0;
		width: 100%;
		z-index: 50;
		min-height: 190px;
		background: transparent url(/images/local/v-202411080924/header-shadow.png) repeat-x 0 0;
		padding: .65rem 0;
		&.-nosidebar{
			background: transparent;
		}
	}
	@include min(1024px){
		&.sticky{
			position: fixed;
			top: 0;
			left: 0;
			background: $color-brand;
			min-height: 0;
			box-shadow: 0 0 25px black;
			.header__logo{
				width: 110px;
			}
			.header__social .list__link{
				background-color: $color-light;
				@include link(&){
					color: $color-dark;
				}
			}
			.fullscreen-search-anchor svg{
				fill: $color-dark;
			}
		}
	}
	&__title {
		position: relative;
		margin: 0 auto;

		@include min(500px) {
			margin: 0;
		}
	}

	&__home-link {
		@include fill;
	}

	&__content {
		position: relative;
		z-index: 2;
		max-width: 1604px;
		@include min(900px) {
			display: grid;
			grid-template-columns: max-content 1fr max-content;
			justify-content: space-between;
			grid-template-areas:
				"header__heading-container . header__additional"
				"main-nav main-nav main-nav";
		}

		@include min(1200px) {
			grid-template-areas: "header__heading-container main-nav header__additional";
		}
	}
	&__heading-container {
		grid-area: header__heading-container;
		position: relative;
		@include flex(df, aic, jcc, fdc);
		@include min(800px) {
			flex-direction: row;
			.header__heading{
				text-align: left;
			}
		}
	}
	&__logo {
		display: block;
		font-size: 1rem;
		@include min(600px){
			margin: 0 1.5rem 0 0;
		}
	}

	&__heading {
		position: relative;
		text-align: center;
		padding: 0;
		color: $color-dark;
		line-height: 1;
		font-size: rem(34px);
	}
	&__subheading {
		display: block;
		margin-top: .5em;
		font-size: rem(15px);
	}
	&__home-link {
		color: inherit;
		text-decoration-color: none;
	}

	&__additional {
		grid-area: header__additional;
		@include flex(df, aic, jcc);
		gap: .5rem;
		margin-top: 1rem;
		@include min(600px){
			margin-top: 12px;
		}
		@include min(900px){
			position: static;
		}
	}
	&__social{
		.list{
			@include flex(df, fww, aic);
			gap: 1rem;
			margin-bottom: 0;
			&__item{
				display: inline-flex;
				margin-bottom: 0;
			}
			&__link{
				@include flex(df, aic, jcc);
				padding: .5rem 1.5rem;
				min-height: 50px;
				font-size: rem(14px);
				background-color: $color-brand;
				font-weight: 700;
				border-radius: 12px;
				display: inline-flex;
				box-shadow: 0 0 5px rgba(#000, .5);
				@include min(600px){
					background-color: $color-brand;
				}
				&.-facebook{
					background-color: $color-light;
					padding: .5rem 0.8125rem;
					@include link(&) {
						svg {
							fill: $color-brand;
						}
					}

					@include link-over(&) {
						svg {
							fill: #1877F2;
						}
					}
				}
			}
		}
		object {
			pointer-events: none;
		}

		@include link() {
			text-decoration: none;
			svg {
				fill: $color-light;
			}
		}

		@include link-over() {
			svg {
				fill: $color-dark;
			}
		}
	}
	&__nav{
		grid-area: main-nav;
		@include flex(df, aic, jcc);
		flex-shrink: 0;
	}
	&__nav-button {
		position: absolute;
		right: 1rem;
		top: 4rem;
		display: inline-flex;
		align-items: flex-end;
		justify-content: center;
		flex-direction: column;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		@include min(600px) {
			display: none;
		}

		@include link(&) {
			text-decoration: none;
		}

		> .hamburger {
			padding: 8px 7px;
			//border: 1px solid currentColor;
			border-radius: 5px;
		}

		.nav-button__title {
			font-size: rem(15px);
			margin: 0;
		}
	}
}
