.sidebar {
	nav.nav{
		position: sticky;
		top: 130px;
    	padding-bottom: 2rem;
	}
	.nav {
		&__upper_node_link {
			position: relative;
			display: inline-flex;
			font-size: rem(25px);
			font-weight: 700;
			padding: 0.4rem 0 2.7rem 2rem;
			color: $color-base;
			text-decoration: none;
			@include link-over(&){
				text-decoration: underline;
			}

			&::before {
				position: absolute;
				content: "";
				top: 18px;
				left: 0;
				width: 9px;
				height: 12px;
				background: transparent url(/images/local/icons/v-202411080924/arrow-left.png) no-repeat center center / cover;
			}
		}

		//ul top level
		&.nav.-root-nav {
			padding: 0 1rem 0 2rem;
			margin-top: 0;
			list-style-type: none;
		}

		//ul lower level
		&.nav.-sub-nav {
			margin-top: .75em;
			margin-bottom: 0;
			padding-left: 24px;
		}

		&__list {
			padding-left: 0em;
			list-style-type: none;
			margin: 2.2em 0 1.5em;
		}

		&__item {
			font-size: rem(17px);
			font-weight: 400;
			margin: 0 0 .5em;

			.nav__item {
				font-size: rem(16px);
				font-weight: 300;
				text-transform: none;
			}

			&.-has-children {
				>.nav__link {
					font-weight: 500;
				}
			}

			&.-expanded {
				background-color: rgba(0, 0, 0, .1);
			}

			&.-current {
				background-color: rgba(0, 0, 0, .1);
			}
		}

		&__description {
			margin: 0 0 .25em;
			font-size: 1rem;
		}

		&__link {
			position: relative;

			+.nav.-sub-nav {
				.nav__link.-expandable .nav__toggle-button {
					left: -26px;
					top: .36em;
					width: 12px;
					height: 12px;
					background-image: encode-svg('<svg width="12" height="12" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M5,5L5,1C5,0.448 5.448,0 6,0C6.552,0 7,0.448 7,1L7,5L11,5C11.552,5 12,5.448 12,6C12,6.552 11.552,7 11,7L7,7L7,11C7,11.552 6.552,12 6,12C5.448,12 5,11.552 5,11L5,7L1,7C0.448,7 0,6.552 0,6C0,5.448 0.448,5 1,5L5,5Z"/></svg>');
				}
			}

			@include link(&) {
				color: $color-base;
				text-decoration: none;
			}

			@include link-over(&) {
				text-decoration: underline;
			}

			&[aria-expanded=true]~.nav {
				display: block;
			}

			&[aria-expanded=false]~.nav {
				display: none;
			}

			&.-active {
				pointer-events: none;
			}

			&.-active,
			&.-active-parent {
				text-decoration: none;
				font-weight: bold;

				@include link(&) {
					color: $color-brand;
				}
			}
		}

		&__toggle-button {
			font-size: 1rem;
			line-height: 0;
			position: absolute;
			transition: transform .2s ease-out;
			transform-origin: 50% 50%;
			left: -28px;
			top: .18em;
			width: 16px;
			height: 16px;
			cursor: pointer;
			background: transparent encode-svg('<svg width="16" height="16" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M7,7L7,1C7,0.448 7.448,0 8,0C8.552,0 9,0.448 9,1L9,7L15,7C15.552,7 16,7.448 16,8C16,8.552 15.552,9 15,9L9,9L9,15C9,15.552 8.552,16 8,16C7.448,16 7,15.552 7,15L7,9L1,9C0.448,9 0,8.552 0,8C0,7.448 0.448,7 1,7L7,7Z"/></svg>') no-repeat 0 0;
		}

		&[aria-expanded="true"] .nav__toggle-button {
			transform: rotate(225deg);
		}
	}
}
