.breadcrumbs {
	text-align: right;
	color: $color-dark;
	padding-top: 1em;
	padding-bottom: 1em;
	.centered.-padded{
		padding-left: 0;
		padding-right: 0;
		width: 94%;
	}
	@include min(600px){
		position: absolute;
		bottom: 25px;
		right: 0;
		left: 0;
		z-index: 40;
		&.-nosidebar{
			text-align: center;
			bottom: 12rem;
			left: 0;
			right: 0;
			.breadcrumb-nav {
				&__item {
					&:not(.-active)::after {
						color: $color-light;
					}
				}
				@include link(".breadcrumb-nav__link") {
					color: $color-light;
				}
				@include link(".breadcrumb-nav__link.-active") {
					color: $color-light;
				}
			}
		}
	}
}

.breadcrumb-nav {
	margin-top: 0;
	margin-bottom: 0;

	&__item {
		display: inline;


		&:not(.-active)::after {
			color: $color-base;
			content: " \>";
			padding: 0 0.5rem;
		}
	}

	&__link {
		font-weight: 500;
		text-decoration: underline;
		display: inline-block;
	}

	@include link(".breadcrumb-nav__link") {
		color: $color-base;
		text-decoration: underline;
	}

	@include link(".breadcrumb-nav__link.-active") {
		color: $color-base;
		pointer-events: none;
		font-weight: 700;
		text-decoration: none;
	}

	@include link-over(".breadcrumb-nav__link") {
		text-decoration: none;
	}
}
