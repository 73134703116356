@charset "UTF-8";
/*!
*  Author: WEBHOUSE, s. r. o.
*  Version: 1.0
*/
/*
* BASE DEPENDENCIES
*/
@font-face {
  font-family: "OpenSans";
  src: url("/v-202411080924/font/local/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans";
  src: url("/v-202411080924/font/local/OpenSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "OpenSans";
  src: url("/v-202411080924/font/local/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans";
  src: url("/v-202411080924/font/local/OpenSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "OpenSans";
  src: url("/v-202411080924/font/local/OpenSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans";
  src: url("/v-202411080924/font/local/OpenSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "OpenSans";
  src: url("/v-202411080924/font/local/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans";
  src: url("/v-202411080924/font/local/OpenSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "OpenSans";
  src: url("/v-202411080924/font/local/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans";
  src: url("/v-202411080924/font/local/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "OpenSans";
  src: url("/v-202411080924/font/local/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans";
  src: url("/v-202411080924/font/local/OpenSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
/*
* SHARED
*/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*************************
* MIXINY PRO ODRÁŽKY
*************************/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/*==============================
	DEFAULTS
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	TABS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*==============================
	CALENDAR -PAGE
==============================*/
@keyframes fade-into-foreground {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes fade-into-background {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.75);
    opacity: 0;
  }
}
@keyframes pop-into-foreground {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes pop-into-foreground-with-bounce {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  72% {
    transform: scale(1.07);
    opacity: 0.72;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
  }
}
body {
  font-family: "Open Sans", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif;
}

h1, h2, h3, h4 {
  font-weight: 700;
  line-height: 1.15;
  margin: 0 0 0.3em 0;
  /* * + & {
  	margin-top: 2em;
  } */
}

h5, h6 {
  font-size: 600;
  margin: 0 0 0.5em 0;
}
* + h5, * + h6 {
  margin-top: 1.65em;
}

h2,
h3.-size-h2 {
  font-size: 1.625rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.375rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1.125rem;
}

a,
a:link {
  color: #d90014;
}

a:visited {
  color: #a6000f;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

._fsr {
  position: absolute !important;
  left: -9999rem !important;
  top: auto;
  outline: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

._nls {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

/*
* COMMON BLOCKS
*/
html {
  scroll-behavior: smooth;
}

body {
  margin: 0 auto;
  font-family: "Open Sans", sans-serif;
  overflow: visible;
  overflow-x: hidden;
}

.centered {
  max-width: 1600px;
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 0;
  margin: 0 -2.25vw 3em;
}
.grid__item {
  flex-grow: 0;
  padding: 2.25vw;
}
.grid__item.-fl {
  display: flex;
  flex-direction: column;
}
.grid__item.-fl > :only-child {
  flex-grow: 1;
}
.grid.-no-spacing {
  margin-left: 0;
  margin-right: 0;
}
.grid.-no-spacing .grid__item {
  padding: 0;
}
.grid.-halves .grid__item {
  display: grid;
}
@media screen and (min-width: 649px) {
  .grid.-halves .grid__item {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}
.grid.-thirds .grid__item {
  display: grid;
}
@media screen and (min-width: 600px) and (max-width: 800px) {
  .grid.-thirds .grid__item {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}
@media screen and (min-width: 799px) {
  .grid.-thirds .grid__item {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}
@media screen and (min-width: 800px) {
  .grid.-thirds:not(.-no-spacing) {
    margin-left: -1.6875vw;
    margin-right: -1.6875vw;
  }
  .grid.-thirds:not(.-no-spacing) .grid__item {
    padding: 1.6875vw;
  }
}
.grid.-quarters .grid__item {
  display: grid;
}
@media screen and (min-width: 600px) and (max-width: 800px) {
  .grid.-quarters .grid__item {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}
@media screen and (min-width: 800px) and (max-width: 1024px) {
  .grid.-quarters .grid__item {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}
@media screen and (min-width: 1023px) {
  .grid.-quarters .grid__item {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}
@media screen and (min-width: 800px) {
  .grid.-quarters:not(.-no-spacing) {
    margin-left: -1.6875vw;
    margin-right: -1.6875vw;
  }
  .grid.-quarters:not(.-no-spacing) .grid__item {
    padding: 1.6875vw;
  }
}
@media screen and (min-width: 1024px) {
  .grid.-quarters:not(.-no-spacing) {
    margin-left: -1.125vw;
    margin-right: -1.125vw;
  }
  .grid.-quarters:not(.-no-spacing) .grid__item {
    padding: 1.125vw;
  }
}

.agnostic-grid {
  margin: 0 0 3em;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
}

a:focus.keyboard-focus,
video:focus.keyboard-focus,
button:focus.keyboard-focus,
summary:focus.keyboard-focus {
  outline: 3px lightskyblue solid;
  outline-offset: 2px;
  -moz-outline-radius: 3px;
}

/*==============================
	INPUT, TEXTAREA, SELECT
==============================*/
.textarea, .input {
  transition: border-color 0.11s ease, box-shadow 0.21s ease;
  box-shadow: 0;
  width: 100%;
  font-size: 1em;
  border-radius: 4px;
  border-color: #cccccc;
  border-width: 1px;
  background-color: #ffffff;
  color: #2a2a2a;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.textarea:focus, .input:focus {
  border-color: #6b9aeb;
  box-shadow: 0 8px 26px rgba(0, 0, 0, 0.12);
  outline: 0;
}
[disabled].textarea, [disabled].input {
  border-color: #e3e3e3;
  background-color: #ebebeb;
  color: #444444;
  cursor: not-allowed;
}

/*==============================
	CHECKBOX, SWITCH, RADIO
==============================*/
[class*=lazy-] {
  transition: filter 0.5s ease-out;
  filter: blur(20px);
}

[class*=lazy-]:not(.loaded) {
  display: block;
  background: #dedede;
  width: 100%;
  min-width: 240px;
  padding-bottom: 56.25%;
}

[class*=lazy-].loaded {
  filter: blur(0);
}

body {
  color: #252c10;
}

::selection {
  background-color: #1a86d0;
  color: #ffffff;
}

.centered {
  max-width: 1600px;
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
}
.centered.-narrow {
  max-width: 920px;
}
.centered.-padded {
  width: 100%;
  padding-left: 4%;
  padding-right: 4%;
}
.centered.-padded-left {
  padding-left: 4%;
}
.centered.-padded-right {
  padding-right: 4%;
}

@media screen and (min-width: 600px) {
  .main-wrapper-cover {
    position: relative;
    z-index: 40;
    background: transparent url(/images/local/v-202411080924/main-wrapper-cover-bg.png) no-repeat 100% 90%;
  }
}

.main-wrapper {
  display: grid;
  grid-gap: 0 50px;
  gap: 0 50px;
}
@media screen and (min-width: 600px) {
  .main-wrapper.-nosidebar {
    position: relative;
    margin-top: 0rem;
    z-index: 40;
    min-height: 30em;
  }
}
@media screen and (min-width: 800px) {
  .main-wrapper.-sidebar {
    grid-template-areas: "sidebar main";
    grid-template-columns: 350px 1fr;
    padding-bottom: 4rem;
  }
  .main-wrapper.-sidebar .main {
    grid-area: main;
  }
  .main-wrapper.-sidebar .sidebar {
    grid-area: sidebar;
    border: solid #e4e4e4;
    border-width: 0 2px;
    padding: 0 1rem;
  }
}
@media screen and (min-width: 1280px) {
  .main-wrapper {
    grid-gap: 0 100px;
    gap: 0 100px;
    margin-top: 0.6rem;
  }
  .main-wrapper.-sidebar {
    grid-template-columns: 466px 1fr;
  }
  .main-wrapper.-sidebar .sidebar {
    padding: 0 1rem 0 15.3%;
  }
}

.table-wrapper {
  max-width: 100%;
  overflow-x: auto;
  margin-bottom: 2em;
}
.table-wrapper table {
  margin-bottom: 1em;
}

.sidebar {
  padding-bottom: 2em;
}

.content {
  margin-left: auto;
  margin-right: auto;
}

.more {
  margin: 2.5rem 0;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem 2.5rem;
}
.more__button {
  background-color: #d90014;
  padding: 1.375em 2em;
  font-weight: 700;
  display: inline-block;
  font-size: 0.875rem;
  letter-spacing: 1px;
  min-width: 220px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  border-radius: 12px;
  text-align: center;
  flex-grow: 1;
}
@media screen and (min-width: 800px) {
  .more__button {
    flex-grow: 0;
  }
}

.more__button,
.more__button:link,
.more__button:visited {
  color: #ffffff;
  text-decoration: none;
}

.more__button:hover,
.more__button:focus,
.more__button:active {
  background-color: #252c10;
  color: #ffffff;
}

.header {
  position: relative;
  background: #d90014;
  color: #ffffff;
  padding: 0.65rem 0 2rem;
}
.header a,
.header a:link,
.header a:visited {
  color: #ffffff;
}
@media screen and (min-width: 600px) {
  .header {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 50;
    min-height: 190px;
    background: transparent url(/images/local/v-202411080924/header-shadow.png) repeat-x 0 0;
    padding: 0.65rem 0;
  }
  .header.-nosidebar {
    background: transparent;
  }
}
@media screen and (min-width: 1024px) {
  .header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    background: #d90014;
    min-height: 0;
    box-shadow: 0 0 25px black;
  }
  .header.sticky .header__logo {
    width: 110px;
  }
  .header.sticky .header__social .list__link {
    background-color: #ffffff;
  }
  .header.sticky .header__social .list__link, .header.sticky .header__social .list__link:link, .header.sticky .header__social .list__link:visited {
    color: #292A2D;
  }
  .header.sticky .fullscreen-search-anchor svg {
    fill: #292A2D;
  }
}
.header__title {
  position: relative;
  margin: 0 auto;
}
@media screen and (min-width: 500px) {
  .header__title {
    margin: 0;
  }
}
.header__home-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.header__content {
  position: relative;
  z-index: 2;
  max-width: 1604px;
}
@media screen and (min-width: 900px) {
  .header__content {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    justify-content: space-between;
    grid-template-areas: "header__heading-container . header__additional" "main-nav main-nav main-nav";
  }
}
@media screen and (min-width: 1200px) {
  .header__content {
    grid-template-areas: "header__heading-container main-nav header__additional";
  }
}
.header__heading-container {
  grid-area: header__heading-container;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media screen and (min-width: 800px) {
  .header__heading-container {
    flex-direction: row;
  }
  .header__heading-container .header__heading {
    text-align: left;
  }
}
.header__logo {
  display: block;
  font-size: 1rem;
}
@media screen and (min-width: 600px) {
  .header__logo {
    margin: 0 1.5rem 0 0;
  }
}
.header__heading {
  position: relative;
  text-align: center;
  padding: 0;
  color: #292A2D;
  line-height: 1;
  font-size: 2.125rem;
}
.header__subheading {
  display: block;
  margin-top: 0.5em;
  font-size: 0.9375rem;
}
.header__home-link {
  color: inherit;
  text-decoration-color: none;
}
.header__additional {
  grid-area: header__additional;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
}
@media screen and (min-width: 600px) {
  .header__additional {
    margin-top: 12px;
  }
}
@media screen and (min-width: 900px) {
  .header__additional {
    position: static;
  }
}
.header__social .list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0;
}
.header__social .list__item {
  display: inline-flex;
  margin-bottom: 0;
}
.header__social .list__link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.5rem;
  min-height: 50px;
  font-size: 0.875rem;
  background-color: #d90014;
  font-weight: 700;
  border-radius: 12px;
  display: inline-flex;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
@media screen and (min-width: 600px) {
  .header__social .list__link {
    background-color: #d90014;
  }
}
.header__social .list__link.-facebook {
  background-color: #ffffff;
  padding: 0.5rem 0.8125rem;
}
.header__social .list__link.-facebook svg, .header__social .list__link.-facebook:link svg, .header__social .list__link.-facebook:visited svg {
  fill: #d90014;
}
.header__social .list__link.-facebook:hover svg, .header__social .list__link.-facebook:focus svg, .header__social .list__link.-facebook:active svg {
  fill: #1877F2;
}
.header__social object {
  pointer-events: none;
}
.header__social a,
.header__social a:link,
.header__social a:visited {
  text-decoration: none;
}
.header__social a svg,
.header__social a:link svg,
.header__social a:visited svg {
  fill: #ffffff;
}
.header__social a:hover svg,
.header__social a:focus svg,
.header__social a:active svg {
  fill: #292A2D;
}
.header__nav {
  grid-area: main-nav;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.header__nav-button {
  position: absolute;
  right: 1rem;
  top: 4rem;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
@media screen and (min-width: 600px) {
  .header__nav-button {
    display: none;
  }
}
.header__nav-button, .header__nav-button:link, .header__nav-button:visited {
  text-decoration: none;
}
.header__nav-button > .hamburger {
  padding: 8px 7px;
  border-radius: 5px;
}
.header__nav-button .nav-button__title {
  font-size: 0.9375rem;
  margin: 0;
}

.additional {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
}
.additional .element-type-heading {
  font-size: 1.625rem;
  color: #292A2D;
  font-weight: 700;
  margin-bottom: 0;
  padding-bottom: 1em;
}
.additional .text-component {
  margin-bottom: 0;
  font-size: 1.0625rem;
}
.additional .text-component h3 {
  font-size: 1.25rem;
}
.additional__content {
  background-color: #ffffff;
  color: #292A2D;
  padding: 0rem 0 2.7rem;
  max-width: 1200px;
  border-radius: 1.5rem;
}
@media screen and (min-width: 600px) {
  .additional__content {
    display: grid;
    gap: 2rem;
    padding: 4.7rem 5% 2.7rem;
  }
  .additional__content .additional__left {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 4rem;
  }
  .additional__content .additional__left .more {
    grid-column: 1/-1;
    justify-content: flex-start;
  }
}
@media screen and (min-width: 1024px) {
  .additional__content {
    grid-template-columns: 2.7fr 1.3fr;
  }
  .additional__content .additional__left + .additional__section {
    margin-left: auto;
  }
}
.additional .official-hours {
  margin-top: 56px;
}
.additional .official-hours b {
  display: inline-block;
  min-width: 2.75em;
  font-weight: 400;
}
.additional .more {
  width: 100%;
}
.additional .more .more__button,
.additional .more .more__button:link,
.additional .more .more__button:visited {
  color: #ffffff;
}
.additional .list__item {
  font-size: 1.0625rem;
  margin-bottom: 0;
}
@media screen and (min-width: 600px) {
  .additional {
    padding: 2rem 0 5rem;
  }
  .additional::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: #fff url(/images/local/v-202411080924/additional-bg.jpg) no-repeat center top/cover;
    z-index: -1;
  }
}
@media screen and (min-width: 1024px) {
  .additional {
    padding: 17% 0 5.3rem;
  }
}
@media screen and (min-width: 1920px) {
  .additional {
    padding: 19.3rem 0 5.3rem;
  }
}
.additional a:not(.more__button),
.additional a:not(.more__button):link,
.additional a:not(.more__button):visited {
  color: #ffffff;
  text-decoration: underline;
}
.additional a:not(.more__button):hover,
.additional a:not(.more__button):focus,
.additional a:not(.more__button):active {
  text-decoration: none;
}

.page-footer {
  text-align: center;
  background-color: #d90014;
  color: #ffffff;
  padding: 3.5em 3rem 3em;
  font-size: 0.875rem;
}
.page-footer__list {
  grid-area: footer-list;
}
.page-footer__webmaster {
  grid-area: footer-webmaster;
}
.page-footer__heading {
  grid-area: footer-heading;
  font-size: 1.25rem;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.page-footer__logo {
  margin-right: 1.5rem;
}
@media screen and (min-width: 600px) {
  .page-footer .centered {
    display: grid;
    grid-template: "footer-heading footer-webmaster" "footer-list footer-webmaster";
    gap: 1rem;
  }
  .page-footer__list {
    text-align: left;
  }
  .page-footer__webmaster {
    text-align: right;
  }
}
.page-footer a,
.page-footer a:link,
.page-footer a:visited {
  color: #ffffff;
}

.article__heading {
  font-size: 2.25rem;
  text-transform: uppercase;
  line-height: 1.15;
  margin: 0 0 0.75em;
  font-weight: 700;
}
.article__heading [contenteditable=true] {
  text-transform: none;
}
@media screen and (min-width: 600px) {
  .-nosidebar .article__heading {
    position: absolute;
    bottom: calc(100% + 15.05rem);
    left: 0;
    right: 0;
    text-align: center;
    color: #ffffff;
    z-index: 40;
  }
}
.article__approved {
  padding: 1em 0 0;
}
.article__footer {
  margin: 1.5em 0 2em;
}
.article__footer div {
  display: inline-block;
}
.article__footer div input[type=date],
.article__footer div input[type=time] {
  display: inline;
  border: 0;
  padding: 0 0.5em;
  border-radius: 3px;
}
.article__footer .invalid ul {
  margin: 0;
  padding: 0 0 0 0.5em;
}
.article__perex {
  font-size: 1.1em;
  margin-bottom: 1.5em;
}
.article__image {
  margin-left: auto;
  margin-right: auto;
}
.article__body {
  margin-bottom: 0em;
}
.article__author {
  text-align: left;
  clear: both;
  margin: 1.5em 0;
  display: inline;
  font-style: normal;
}
.article__datespan {
  margin-bottom: 3em;
}
.article__date {
  font-weight: bold;
}

.article__body .list.documents {
  display: flex;
  flex-direction: column;
}
.article__body .list.documents .list__item {
  margin: 0 0 12px;
}

.article-link {
  display: contents;
}
.article-link__image {
  display: block;
  max-width: 240px;
  margin: 0 1rem 0 0;
  flex-shrink: 0;
}
.article__link-elements .article-link__image {
  max-width: 100px;
}
@media screen and (min-width: 500px) {
  .article-link__image {
    display: inline-block;
    float: left;
    margin: 0.5rem 1rem 0 0;
  }
}
.article-link__title {
  font-size: 1.125rem;
}
.article-link__description {
  font-size: 1rem;
  margin-top: 0;
}

.article-link .article-link__title,
.article-link:link .article-link__title,
.article-link:visited .article-link__title {
  color: #d90014;
  text-decoration: underline;
}

.article-link:visited .article-link__title {
  color: #a6000f;
}

.article-link:hover .article-link__title,
.article-link:focus .article-link__title,
.article-link:active .article-link__title {
  text-decoration: none;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 1rem;
}
.list .list {
  padding: 0 0 0 2em;
}
.list__item {
  margin: 0 0 0.7em;
}
.list__description {
  margin-top: 0.25em;
}
.-chosen .list {
  display: grid;
  margin: 0;
}
@media screen and (min-width: 600px) and (max-width: 1024px) {
  .-chosen .list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
}
@media screen and (min-width: 1023px) {
  .-chosen .list {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3rem;
    gap: 3rem;
  }
}
.board .list {
  display: grid;
}
@media screen and (min-width: 599px) {
  .board .list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2px;
    gap: 2px;
  }
}
@media screen and (min-width: 600px) {
  .board .list {
    border: 2px solid #e4e4e4;
    background-color: #e4e4e4;
  }
}
@media screen and (min-width: 1px) and (max-width: 599.9px) {
  .news .list, .board .list, .homepage-events .list {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
    scroll-snap-type: x mandatory;
    scroll-padding-left: 20px;
    touch-action: manipulation;
    -webkit-overflow-scrolling: touch;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .news .list li, .board .list li, .homepage-events .list li {
    flex-shrink: 0;
    width: 85%;
    scroll-snap-stop: always;
    scroll-snap-align: start;
  }
  .news .list li + li, .board .list li + li, .homepage-events .list li + li {
    margin-left: 20px;
  }
}
.scrollslider-js > .list {
  scrollbar-width: none;
  padding-bottom: 60px;
  margin-bottom: -40px;
}

.org-contacts__contact span:not(:first-of-type)::before {
  background-color: #252c10;
}

/*
* DESIGN COMPONENTS
*/
.button {
  border-radius: 4px;
  padding: 5px 2em;
  margin: 0;
  min-height: 42px;
  background-color: #252c10;
  color: #ffffff;
  text-align: center;
  transition: background-color 0.15s ease;
  position: relative;
  cursor: pointer;
}
.button:not([disabled]):hover, .button:not([disabled]):focus, .button:not([disabled]):active {
  z-index: 3;
  outline: 0;
}
.button[disabled] {
  color: #999999;
  background-color: #ebebeb;
  cursor: not-allowed;
}
.button.-icon::before {
  vertical-align: middle;
  margin-right: 0.75em;
  display: inline-block;
}
.button.-block {
  display: block;
  width: 100%;
}
.button.-inverted {
  background-color: #252c10;
  color: #ffffff;
}
.button.-primary {
  background-color: #d90014;
  color: #ffffff;
}
.button.-primary.-inverted {
  background-color: #ffffff;
  color: #d90014;
}
.button.-primary:not([disabled]):hover, .button.-primary:not([disabled]):focus, .button.-primary:not([disabled]):active {
  background-color: #252c10;
  color: #ffffff;
}

.input {
  min-height: 42px;
  padding: 0.3em 1em;
}
.input.-inline {
  display: inline-block;
  width: auto;
  margin-right: 0.25em;
}
.input.-main {
  min-height: 54px;
  font-size: 1.15em;
}

.textarea {
  padding: 0.5em 1em;
  resize: vertical;
  height: auto;
  min-height: 10em;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
/* MARKUP */
/*
<button class="hamburger" type="button">
  <span class="hamburger-box">
    <span class="hamburger-inner"></span>
  </span>
</button>
 */
.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #ffffff;
}

.hamburger-box {
  width: 22px;
  height: 19px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 22px;
  height: 3px;
  background-color: #ffffff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -8px;
}
.hamburger-inner::after {
  bottom: -8px;
}

/*
 * Collapse
 */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.universal-popup-component {
  position: fixed;
  overflow: hidden;
  z-index: 90;
  left: -9999rem;
  width: 0;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  color: #323232;
}
.universal-popup-component::before {
  position: fixed;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.15s ease-out;
}
.universal-popup-component__form {
  position: relative;
  z-index: 2;
  transform: scale(0);
  background-color: #ffffff;
  border-radius: 15px;
  transition: transform 0.15s ease-out, box-shadow 0.2s ease-out;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  max-width: 80vw;
  max-height: 70vh;
  min-height: 10em;
  width: 36em;
  padding: 3vmax;
}
.universal-popup-component__heading {
  font-family: inherit;
  color: inherit;
  margin: 0 0 0.66em;
  padding: 0;
  text-transform: none;
  font-size: 2.1875rem;
}
.universal-popup-component__row {
  display: flex;
  flex-direction: column;
  margin: 0;
}
.universal-popup-component__row:empty {
  display: none;
}
.universal-popup-component__row:not(:last-child) {
  margin-bottom: 20px;
}
.universal-popup-component__row.-end {
  align-items: flex-end;
}
.universal-popup-component__row.-center {
  align-items: center;
}
.universal-popup-component__label {
  font-family: inherit;
  font-size: inherit;
  margin: 0 0 6px;
}
.universal-popup-component__input {
  font-family: inherit;
  font-size: inherit;
  height: 54px;
  border-radius: 6px;
  border: 1px #cccccc solid;
  outline: 0;
}
.universal-popup-component__button {
  font-family: inherit;
  font-size: inherit;
  border-radius: 5px;
  background-color: #888888;
  color: #ffffff;
}
.universal-popup-component__close {
  font-size: 1.25rem;
  font-weight: 600;
  position: absolute;
  right: 1em;
  top: 1em;
  line-height: 0;
  width: 20px;
  height: 20px;
  text-decoration: none;
}
.universal-popup-component.-visible, .universal-popup-component:target {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.universal-popup-component.-visible::before, .universal-popup-component:target::before {
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  background: rgba(0, 0, 0, 0.7);
}
@keyframes popup-bounce {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.universal-popup-component.-visible .universal-popup-component__form, .universal-popup-component:target .universal-popup-component__form {
  z-index: 2;
  animation: popup-bounce 0.3s ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transform-origin: 50% 100%;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}

.breadcrumbs {
  text-align: right;
  color: #292A2D;
  padding-top: 1em;
  padding-bottom: 1em;
}
.breadcrumbs .centered.-padded {
  padding-left: 0;
  padding-right: 0;
  width: 94%;
}
@media screen and (min-width: 600px) {
  .breadcrumbs {
    position: absolute;
    bottom: 25px;
    right: 0;
    left: 0;
    z-index: 40;
  }
  .breadcrumbs.-nosidebar {
    text-align: center;
    bottom: 12rem;
    left: 0;
    right: 0;
  }
  .breadcrumbs.-nosidebar .breadcrumb-nav__item:not(.-active)::after {
    color: #ffffff;
  }
  .breadcrumbs.-nosidebar .breadcrumb-nav .breadcrumb-nav__link,
  .breadcrumbs.-nosidebar .breadcrumb-nav .breadcrumb-nav__link:link,
  .breadcrumbs.-nosidebar .breadcrumb-nav .breadcrumb-nav__link:visited {
    color: #ffffff;
  }
  .breadcrumbs.-nosidebar .breadcrumb-nav .breadcrumb-nav__link.-active,
  .breadcrumbs.-nosidebar .breadcrumb-nav .breadcrumb-nav__link.-active:link,
  .breadcrumbs.-nosidebar .breadcrumb-nav .breadcrumb-nav__link.-active:visited {
    color: #ffffff;
  }
}

.breadcrumb-nav {
  margin-top: 0;
  margin-bottom: 0;
}
.breadcrumb-nav__item {
  display: inline;
}
.breadcrumb-nav__item:not(.-active)::after {
  color: #252c10;
  content: " >";
  padding: 0 0.5rem;
}
.breadcrumb-nav__link {
  font-weight: 500;
  text-decoration: underline;
  display: inline-block;
}
.breadcrumb-nav .breadcrumb-nav__link,
.breadcrumb-nav .breadcrumb-nav__link:link,
.breadcrumb-nav .breadcrumb-nav__link:visited {
  color: #252c10;
  text-decoration: underline;
}
.breadcrumb-nav .breadcrumb-nav__link.-active,
.breadcrumb-nav .breadcrumb-nav__link.-active:link,
.breadcrumb-nav .breadcrumb-nav__link.-active:visited {
  color: #252c10;
  pointer-events: none;
  font-weight: 700;
  text-decoration: none;
}
.breadcrumb-nav .breadcrumb-nav__link:hover,
.breadcrumb-nav .breadcrumb-nav__link:focus,
.breadcrumb-nav .breadcrumb-nav__link:active {
  text-decoration: none;
}

.form {
  padding: 2em 0;
  margin: 0 0 2em 0;
}
.form__fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
.form__fields > :not(.form__section) {
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.form__section {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  flex-basis: 50%;
  padding: 0 1.25rem;
  min-width: 18em;
  margin-bottom: 1.1em;
}
.form__section.-inline {
  display: inline-block;
  padding: 0;
}
.form__section.-full {
  width: 100%;
  flex-basis: 100%;
}
.form__section.-to-end {
  justify-content: flex-end;
}
.form__section.-to-center, .form__section.-to-right {
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 1;
  width: 100%;
  margin-top: 1em;
}
.form__section.-to-center {
  align-items: flex-start;
  justify-content: center;
  text-align: center;
}
.form__section.-to-right {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
}
.form__section.-to-left {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}
.form__section .label {
  margin-bottom: 0.6em;
}
.form__section .label.-inline {
  display: inline-block;
  margin-right: 0.25em;
}
.form__section .button:nth-last-child(n+2), .form__section .button:nth-last-child(n+2) ~ .button {
  margin-bottom: 0.5em;
}

.form__section .button:not(:first-child):not(.-block) {
  margin-left: 0.5em;
}
.form__label {
  display: block;
  margin-bottom: 0.4em;
  cursor: default;
}
.form__file-value {
  color: inherit;
  opacity: 0.8;
}
.form__fieldset {
  padding: 0.85em 1.5em 0.625em;
  border: 1px #cccccc solid;
  border-radius: 4px;
}
.form__legend {
  padding: 0 0.5em;
  font-size: 1.35rem;
  font-weight: bold;
}

.inline-form.form {
  padding-bottom: 0;
}
.inline-form__fields.-bordered-top {
  border-top: 2px solid #c3c3c3;
  padding-top: 1.5em;
}
.inline-form__fields.-bordered-bottom {
  border-bottom: 2px solid #c3c3c3;
  padding-bottom: 0.5em;
}

.contact-form {
  padding: 0;
  margin-bottom: 0;
}
.contact-form .form__section {
  margin-bottom: 0.5em;
}
.contact-form__input, .contact-form__textarea {
  background-color: #ffffff;
  border-color: #292A2D;
  color: #292A2D;
  border-radius: 1px;
}
.contact-form__input {
  min-height: 55px;
}
.contact-form__info:first-child {
  margin: 0.55em 0 0.9em;
}
.contact__line {
  padding-left: 50px;
  margin: 0 0 1em;
}
.contact__line.-location, .contact__line.-phone, .contact__line.-email {
  padding-top: 0.15em;
  padding-bottom: 0.15em;
  background-repeat: no-repeat;
  background-position: 0 50%;
}
.contact__line.-location {
  background-image: url("data:image/svg+xml;utf8,%3Csvg width='30' height='30' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'%3E%3Cpath d='M15,0.405C20.9,0.405 25.683,5.188 25.683,11.088C25.683,16.989 16.532,29.595 14.879,29.595C13.226,29.595 4.317,16.989 4.317,11.088C4.317,5.188 9.1,0.405 15,0.405ZM14.989,5.854C17.886,5.854 20.234,8.202 20.234,11.1C20.234,13.996 17.886,16.344 14.989,16.344C12.092,16.344 9.744,13.996 9.744,11.1C9.744,8.202 12.092,5.854 14.989,5.854Z' fill='%23ffffff'/%3E%3C/svg%3E");
}
.contact__line.-phone {
  background-image: url("data:image/svg+xml;utf8,%3Csvg width='30' height='30' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'%3E%3Cpath d='M221.594,1599.46L204.711,1599.46C204.014,1599.46 203.449,1598.9 203.449,1598.2L203.449,1570.12C203.449,1569.42 204.014,1568.85 204.711,1568.85L221.594,1568.85C222.291,1568.85 222.856,1569.42 222.856,1570.12L222.856,1598.2C222.856,1598.9 222.295,1599.46 221.594,1599.46ZM213.124,1594.92C213.913,1594.92 214.552,1595.56 214.552,1596.35C214.552,1597.14 213.913,1597.78 213.124,1597.78C212.336,1597.78 211.697,1597.14 211.697,1596.35C211.697,1595.56 212.336,1594.92 213.124,1594.92ZM219.169,1571.7L207.134,1571.7C206.498,1571.7 205.988,1572.22 205.988,1572.85L205.988,1591.76C205.988,1592.39 206.501,1592.9 207.134,1592.9L219.169,1592.9C219.803,1592.9 220.315,1592.39 220.315,1591.76L220.315,1572.85C220.315,1572.22 219.805,1571.7 219.169,1571.7ZM23.166,29.805L6.834,29.805C6.16,29.805 5.613,29.259 5.613,28.584L5.613,1.416C5.613,0.742 6.16,0.195 6.834,0.195L23.166,0.195C23.84,0.195 24.387,0.742 24.387,1.416L24.387,28.584C24.387,29.257 23.843,29.802 23.166,29.805ZM14.973,25.412C15.735,25.412 16.354,26.03 16.354,26.793C16.354,27.556 15.735,28.175 14.973,28.175C14.21,28.175 13.592,27.556 13.592,26.793C13.592,26.03 14.21,25.412 14.973,25.412ZM20.82,2.949L9.178,2.949C8.563,2.951 8.069,3.447 8.069,4.057L8.069,22.352C8.069,22.964 8.566,23.461 9.178,23.461L20.82,23.461C21.432,23.461 21.928,22.964 21.928,22.352L21.928,4.057C21.928,3.447 21.435,2.951 20.82,2.949Z' fill='%23ffffff'/%3E%3C/svg%3E");
}
.contact__line.-email {
  background-image: url("data:image/svg+xml;utf8,%3Csvg width='30' height='30' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'%3E%3Cpath d='M26.198,22.835L3.802,22.835C3.622,22.835 3.449,22.806 3.287,22.753L10.652,15.388L14.704,17.482L14.856,17.778L15.068,17.669L15.151,17.713L15.212,17.596L19.389,15.455L26.694,22.759C26.538,22.808 26.371,22.835 26.198,22.835ZM20.94,14.66L27.857,11.116L27.857,21.176C27.857,21.3 27.843,21.421 27.817,21.537L20.94,14.66ZM9.105,14.589L2.178,21.516C2.155,21.407 2.143,21.293 2.143,21.176L2.143,10.992L9.105,14.589ZM15.07,15.804L2.143,9.125L2.143,8.824C2.143,7.908 2.886,7.165 3.802,7.165L26.198,7.165C27.114,7.165 27.857,7.908 27.857,8.824L27.857,9.253L15.07,15.804Z' fill='%23ffffff'/%3E%3C/svg%3E");
}

.search {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  flex-grow: 0;
  padding: 1em 0 1em;
  margin-left: auto;
}
@media screen and (min-width: 600px) {
  .search {
    padding: 1rem 0 1rem 1rem;
  }
  .search__form {
    flex-grow: 0;
  }
  .search__input {
    width: 13em;
  }
}

/*******************************
* FULLSCREEN-SEARCH
*******************************/
.fullscreen-search-anchor {
  z-index: 5;
  white-space: nowrap;
  font-family: "Open Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.fullscreen-search-anchor__container {
  flex-shrink: 0;
}
.fullscreen-search-anchor__text {
  padding-right: 0.5em;
}
.fullscreen-search-anchor svg {
  display: inline-block;
  width: 18px;
  height: 18px;
  fill: #d90014;
}
.fullscreen-search-anchor, .fullscreen-search-anchor:link, .fullscreen-search-anchor:visited {
  text-decoration: underline;
}
.fullscreen-search-anchor:hover, .fullscreen-search-anchor:focus, .fullscreen-search-anchor:active {
  text-decoration: none;
}

@media screen and (min-width: 1024px) {
  .fullscreen-search-anchor {
    position: static;
  }
}
.fullscreen-search {
  transition: background-color 0.1s ease-out;
  position: fixed;
  top: 0;
  left: -999em;
  z-index: 19000;
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  background: rgba(217, 0, 20, 0.9);
}
.fullscreen-search:target, .fullscreen-search[data-is-shown=true] {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: auto;
  height: auto;
  backdrop-filter: blur(8px) grayscale(100%);
}
.fullscreen-search form {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  width: 80%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 10% 0 0 0;
  text-align: left;
  color: #ffffff;
  line-height: 1.4;
}
@media screen and (min-height: 35em) {
  .fullscreen-search form {
    padding-top: 14%;
  }
}
@media screen and (orientation: portrait) {
  .fullscreen-search form {
    padding-top: 33%;
  }
}
.fullscreen-search__close {
  transition: background-color 0.15s ease-out;
  position: absolute;
  top: 0;
  right: 0;
  width: 72px;
  height: 72px;
  cursor: pointer;
  background: transparent url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23ffffff' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E") no-repeat 50% 50%;
}
.fullscreen-search .fullscreen-search__close:hover,
.fullscreen-search .fullscreen-search__close:focus,
.fullscreen-search .fullscreen-search__close:active {
  background-color: #292A2D;
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23ffffff' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E");
}
.fullscreen-search__label {
  font-size: 1.125rem;
  display: block;
  margin: 0 0 0.9em 0;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
}
.fullscreen-search__container {
  position: relative;
  width: 100%;
}
.fullscreen-search__button {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent none;
  box-shadow: none;
  appearance: none;
  border: 0;
  background: transparent;
  color: #ffffff;
  width: 2em;
  height: 2em;
  position: absolute;
  right: 0;
  top: 50%;
  margin: -1.2em 0 0 0;
  padding: 0;
}
.fullscreen-search__button:focus {
  box-shadow: none;
}
@media screen and (min-width: 1100px) {
  .fullscreen-search__button {
    width: 3.8em;
    height: 3.8em;
    margin: -2.2em 0 0 0;
  }
}
@media screen and (min-width: 1600px) {
  .fullscreen-search__button {
    width: 5em;
    height: 5em;
    margin: -2.5em 0 0 0;
  }
}
.fullscreen-search__input {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent none;
  box-shadow: none;
  box-sizing: border-box;
  font-size: 1.5rem;
  width: 100% !important;
  height: auto !important;
  font-family: "Open Sans", sans-serif;
  background: transparent !important;
  color: #ffffff;
  outline: 0;
  font-weight: normal;
  padding: 0 1em 0 0 !important;
  border: dotted rgba(255, 255, 255, 0.8) !important;
  border-width: 0 0 2px !important;
  text-indent: 0 !important;
}
.fullscreen-search__input:focus {
  box-shadow: none !important;
}
.fullscreen-search__input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}
@media screen and (min-width: 600px) {
  .fullscreen-search__input {
    font-size: 4vw;
  }
}
@media screen and (min-width: 1600px) {
  .fullscreen-search__input {
    font-size: 5rem;
  }
}

#google_translate_element {
  display: inline-block;
  margin: 0;
  align-self: center;
  flex-shrink: 0;
}
#google_translate_element.field {
  background-color: transparent;
}
#google_translate_element .goog-te-gadget-simple {
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
#google_translate_element .goog-te-gadget-simple, #google_translate_element .goog-te-gadget-simple * {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem !important;
}
#google_translate_element .goog-te-gadget-simple .goog-te-gadget-icon {
  width: 24px;
  height: 24px;
  padding: 0;
  order: 2;
  margin: 0;
  background-repeat: no-repeat;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup] {
  margin-right: 0;
  display: none;
  text-decoration: none !important;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(1) {
  padding: 0 3px;
  display: inline-block;
  color: #292A2D;
  text-decoration: underline;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(1):hover, #google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(1):focus, #google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(1):active {
  text-decoration: none;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(2) {
  padding-right: 3px;
  border-left: 0 !important;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(3) {
  display: none;
  background: none;
  color: #292A2D !important;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup] img {
  display: none;
}

@media screen and (max-width: 599px) {
  #google_translate_element,
  #goog-gt-tt,
  #goog-gt-tt + div > object,
  iframe.goog-te-menu-frame,
  iframe.goog-te-banner-frame {
    display: none !important;
  }
}
.main-nav {
  position: relative;
  color: #292A2D;
  padding: 0 0 1rem;
}
@media screen and (min-width: 1200px) {
  .main-nav {
    padding-bottom: 0;
  }
}
.main-nav .nav.-root-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px 0 0;
  padding: 0;
}
@media screen and (min-width: 600px) {
  .main-nav .nav.-root-nav {
    flex-direction: row;
  }
}
@media screen and (min-width: 800px) {
  .main-nav .nav.-root-nav {
    flex-wrap: nowrap;
  }
}
.main-nav .nav__item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.5rem 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1.0625rem;
  font-weight: 700;
  text-align: center;
}
.main-nav .nav__link {
  position: relative;
  display: block;
  padding: 0.75em 0;
  line-height: 1;
}
.main-nav .nav__link::after {
  position: absolute;
  content: "";
  top: 100%;
  left: 50%;
  width: 0px;
  height: 4px;
  transform: translateX(-50%);
  background-color: #d90014;
  opacity: 0;
  transition: all 0.2s ease-in;
}
.header.sticky .main-nav .nav__link::after {
  background-color: #ffffff;
}
.main-nav .nav__link, .main-nav .nav__link:link, .main-nav .nav__link:visited {
  text-decoration: none;
}
.main-nav .nav__link:hover::after, .main-nav .nav__link:focus::after, .main-nav .nav__link:active::after {
  width: 40px;
  opacity: 1;
}
.main-nav .nav__link.-active {
  pointer-events: none;
}
.main-nav .nav__link.-active, .main-nav .nav__link.-active-parent {
  text-decoration: none;
}
.main-nav .nav__link.-active::after, .main-nav .nav__link.-active-parent::after {
  width: 40px;
  opacity: 1;
}
.main-nav .nav__description {
  display: none;
}
.main-nav__home-link {
  display: none;
}
@media screen and (min-width: 1100px) {
  .main-nav:not(.sticky .main-nav) .nav {
    position: relative;
  }
  .main-nav:not(.sticky .main-nav) .nav__item:nth-child(2) {
    margin-right: 77px;
  }
  .main-nav:not(.sticky .main-nav) .nav__item:nth-child(3) {
    margin-left: 77px;
  }
  .main-nav:not(.sticky .main-nav) .main-nav__home-link {
    position: absolute;
    display: inline-block;
    left: 50%;
    top: 0;
  }
}
@media screen and (max-width: 599px) {
  .main-nav {
    transition: max-height 0.3s ease-out;
    max-height: 0;
    overflow: hidden;
    padding: 0;
  }
  .main-nav__item {
    animation: menuFadeOut 0.1s ease-out 0s forwards;
    opacity: 0;
  }
  .main-nav__item:nth-of-type(2) {
    animation-delay: 50ms !important;
  }
  .main-nav__item:nth-of-type(3) {
    animation-delay: 100ms !important;
  }
  .main-nav__item:nth-of-type(4) {
    animation-delay: 150ms !important;
  }
  .main-nav__item:nth-of-type(5) {
    animation-delay: 200ms !important;
  }
  .main-nav__item:nth-of-type(6) {
    animation-delay: 250ms !important;
  }
  .main-nav__item:nth-of-type(7) {
    animation-delay: 300ms !important;
  }
  .main-nav__item:nth-of-type(8) {
    animation-delay: 350ms !important;
  }
  .main-nav__item:nth-of-type(9) {
    animation-delay: 400ms !important;
  }
  .main-nav__item:nth-of-type(10) {
    animation-delay: 450ms !important;
  }
  .header__nav--toggled .main-nav {
    max-height: 20rem;
  }
  .header__nav--toggled .main-nav__item {
    animation: menuFadeIn 0.3s ease-out 0s forwards;
  }
}

.figure__caption {
  padding: 0.725em 10px 1.5em;
  border-bottom: 1px #cccccc solid;
}
.figure__caption.-photo, .figure__caption.-video, .figure__caption.-illustration {
  padding-left: 30px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 10px 0.72em;
  background-size: 20px auto;
}
@media screen and (min-width: 800px) {
  .figure__caption.-photo, .figure__caption.-video, .figure__caption.-illustration {
    padding-left: 50px;
    background-position: 10px 0.48em;
    background-size: auto;
  }
}
.figure__caption.-photo {
  background-image: icon("camera", #ccc);
}
.figure__caption.-video {
  background-image: icon("movie", #ccc);
}
.figure__caption.-illustration {
  background-image: icon("picture", #ccc);
}

.text-component {
  margin-bottom: 2em;
  overflow: hidden;
}
.text-component p:first-child {
  margin-top: 0;
}
.text-component p:last-child {
  margin-bottom: 0;
}
.text-component .image-align-left,
.text-component .image-align-right {
  margin: 0;
}
@media screen and (min-width: 500px) {
  .text-component .image-align-left img,
  .text-component .image-align-right img {
    max-width: 42.5%;
  }
}
.text-component .image-align-left img {
  float: left;
  margin: 0 1em 0.75em 0;
}
.text-component .image-align-left > *:first-of-type img {
  clear: both;
}
.text-component .image-align-right img {
  float: right;
  margin: 0 0 0.75em 1em;
}
.text-component .image-align-right > *:first-of-type img {
  clear: both;
}
.text-component .image-align-center {
  text-align: center;
}
.text-component .image-align-center img {
  margin: 0 0 0.75em;
  max-width: 100%;
}
.text-component [contenteditable=true] .images-container:focus img, .text-component [contenteditable=true] .images-container:hover img,
.text-component [contenteditable=true] .image:focus img,
.text-component [contenteditable=true] .image:hover img {
  box-shadow: 0 0 5px red;
}

.sidebar nav.nav {
  position: sticky;
  top: 130px;
  padding-bottom: 2rem;
}
.sidebar .nav__upper_node_link {
  position: relative;
  display: inline-flex;
  font-size: 1.5625rem;
  font-weight: 700;
  padding: 0.4rem 0 2.7rem 2rem;
  color: #252c10;
  text-decoration: none;
}
.sidebar .nav__upper_node_link:hover, .sidebar .nav__upper_node_link:focus, .sidebar .nav__upper_node_link:active {
  text-decoration: underline;
}
.sidebar .nav__upper_node_link::before {
  position: absolute;
  content: "";
  top: 18px;
  left: 0;
  width: 9px;
  height: 12px;
  background: transparent url(/images/local/icons/v-202411080924/arrow-left.png) no-repeat center center/cover;
}
.sidebar .nav.nav.-root-nav {
  padding: 0 1rem 0 2rem;
  margin-top: 0;
  list-style-type: none;
}
.sidebar .nav.nav.-sub-nav {
  margin-top: 0.75em;
  margin-bottom: 0;
  padding-left: 24px;
}
.sidebar .nav__list {
  padding-left: 0em;
  list-style-type: none;
  margin: 2.2em 0 1.5em;
}
.sidebar .nav__item {
  font-size: 1.0625rem;
  font-weight: 400;
  margin: 0 0 0.5em;
}
.sidebar .nav__item .nav__item {
  font-size: 1rem;
  font-weight: 300;
  text-transform: none;
}
.sidebar .nav__item.-has-children > .nav__link {
  font-weight: 500;
}
.sidebar .nav__item.-expanded {
  background-color: rgba(0, 0, 0, 0.1);
}
.sidebar .nav__item.-current {
  background-color: rgba(0, 0, 0, 0.1);
}
.sidebar .nav__description {
  margin: 0 0 0.25em;
  font-size: 1rem;
}
.sidebar .nav__link {
  position: relative;
}
.sidebar .nav__link + .nav.-sub-nav .nav__link.-expandable .nav__toggle-button {
  left: -26px;
  top: 0.36em;
  width: 12px;
  height: 12px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg width='12' height='12' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5,5L5,1C5,0.448 5.448,0 6,0C6.552,0 7,0.448 7,1L7,5L11,5C11.552,5 12,5.448 12,6C12,6.552 11.552,7 11,7L7,7L7,11C7,11.552 6.552,12 6,12C5.448,12 5,11.552 5,11L5,7L1,7C0.448,7 0,6.552 0,6C0,5.448 0.448,5 1,5L5,5Z'/%3E%3C/svg%3E");
}
.sidebar .nav__link, .sidebar .nav__link:link, .sidebar .nav__link:visited {
  color: #252c10;
  text-decoration: none;
}
.sidebar .nav__link:hover, .sidebar .nav__link:focus, .sidebar .nav__link:active {
  text-decoration: underline;
}
.sidebar .nav__link[aria-expanded=true] ~ .nav {
  display: block;
}
.sidebar .nav__link[aria-expanded=false] ~ .nav {
  display: none;
}
.sidebar .nav__link.-active {
  pointer-events: none;
}
.sidebar .nav__link.-active, .sidebar .nav__link.-active-parent {
  text-decoration: none;
  font-weight: bold;
}
.sidebar .nav__link.-active, .sidebar .nav__link.-active:link, .sidebar .nav__link.-active:visited, .sidebar .nav__link.-active-parent, .sidebar .nav__link.-active-parent:link, .sidebar .nav__link.-active-parent:visited {
  color: #d90014;
}
.sidebar .nav__toggle-button {
  font-size: 1rem;
  line-height: 0;
  position: absolute;
  transition: transform 0.2s ease-out;
  transform-origin: 50% 50%;
  left: -28px;
  top: 0.18em;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: transparent url("data:image/svg+xml;utf8,%3Csvg width='16' height='16' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7,7L7,1C7,0.448 7.448,0 8,0C8.552,0 9,0.448 9,1L9,7L15,7C15.552,7 16,7.448 16,8C16,8.552 15.552,9 15,9L9,9L9,15C9,15.552 8.552,16 8,16C7.448,16 7,15.552 7,15L7,9L1,9C0.448,9 0,8.552 0,8C0,7.448 0.448,7 1,7L7,7Z'/%3E%3C/svg%3E") no-repeat 0 0;
}
.sidebar .nav[aria-expanded=true] .nav__toggle-button {
  transform: rotate(225deg);
}