[class*="lazy-"] {
	transition: filter .5s ease-out;
	filter: blur(20px);
}

[class*="lazy-"]:not(.loaded) {
	display: block;
	background: #dedede;
	width: 100%;
	min-width: 240px;
	padding-bottom: 56.25%;
}

[class*="lazy-"].loaded {
	filter: blur(0);
}

body {
	color: $color-base;
}

::selection {
	background-color: #1a86d0;
	color: #ffffff;
}


.centered {
	max-width: $max-width;
	width: calc(100% - #{$page-margin * 2});
	margin-left: auto;
	margin-right: auto;

	&.-narrow {
		max-width: 920px;
	}

	&.-padded {
		width: 100%;
		padding-left: 4%;
		padding-right: 4%;
	}

	&.-padded-left {
		padding-left: 4%;
	}

	&.-padded-right {
		padding-right: 4%;
	}
}
.main-wrapper-cover{
	@include min(600px) {
		position: relative;
		z-index: 40;
		background: transparent url(/images/local/v-202411080924/main-wrapper-cover-bg.png) no-repeat 100% 90%;
	}
}
.main-wrapper {
   display: grid;
	grid-gap: 0 50px;
   gap: 0 50px;
	@include min(600px) {
		&.-nosidebar{
			position: relative;
			margin-top: 0rem;
    		z-index: 40;
			min-height: 30em;
		}
	}
	@include min(800px) {
		&.-sidebar{
			grid-template-areas: "sidebar main";
			grid-template-columns: 350px 1fr;
			padding-bottom: 4rem;
			.main {
				grid-area: main;
			}
			.sidebar {
				grid-area: sidebar;
				border: solid #e4e4e4;
				border-width: 0 2px;
				padding: 0 1rem;
			}
		}
		//obsah na stránce
	}
	@include min(1280px){
		grid-gap: 0 100px;
		gap: 0 100px;
		margin-top: 0.6rem;
		&.-sidebar{
			grid-template-columns: 466px 1fr;
			.sidebar {
				padding: 0 1rem 0 15.3%;
			}
		}
	}
}

.table-wrapper {
	max-width: 100%;
	overflow-x: auto;
	margin-bottom: 2em;

	table {
		margin-bottom: 1em;
	}
}

.sidebar {
	padding-bottom: 2em;
}

.content {
	margin-left: auto;
	margin-right: auto;
}

.more {
	margin: 2.5rem 0;
	text-align: center;
	@include flex(df, fww, aic, jcc);
	gap: 1rem 2.5rem;

	&__button {
		background-color: $color-brand;
		padding: 1.375em 2em;
		font-weight: 700;
		display: inline-block;
		font-size: rem(14px);
		letter-spacing: 1px;
		min-width: 220px;
		transition: background-color .3s ease-in-out, color .3s ease-in-out;
		border-radius: 12px;
		text-align: center;
		flex-grow: 1;
		@include min(800px){
			flex-grow: 0;
		}
	}
}

@include link(".more__button") {
	color: $color-light;
	text-decoration: none;
}

@include link-over(".more__button") {
	background-color: $color-secondary;
	color: $color-light;
}
