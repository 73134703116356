.main-nav {
	position: relative;
	color: $color-dark;
	padding: 0 0 1rem;
	@include min(1200px){
		padding-bottom: 0
	}
	.nav{
		&.-root-nav {
			@include flex(df, fww, aic, jcc, fdc);
			margin: 10px 0 0;
			padding: 0;
			@include min(600px){
				flex-direction: row;
			}
			@include min(800px){
				flex-wrap: nowrap;
			}
		}
		&__item {
			@include flex(dif, aic, jcc);
			margin: 0 1.5rem 0;
			font-family: $font-headings;
			font-size: rem(17px);
			font-weight: 700;
			text-align: center;
		}

		&__link {
			position: relative;
			display: block;
			padding: .75em 0;
			line-height: 1;
			&::after{
				position: absolute;
				content: "";
				top: 100%;
				left: 50%;
				width: 0px;
				height: 4px;
				transform: translateX(-50%);
				background-color: $color-brand;
				opacity: 0;
				transition: all .2s ease-in;
				.header.sticky &{
					background-color: $color-light;
				}
			}

			@include link(&) {
				text-decoration: none;
			}
			@include link-over(&){
				&::after{
					width: 40px;
					opacity: 1;
				}
			}

			&.-active{
				pointer-events: none;
			}

			&.-active,
			&.-active-parent {
				text-decoration: none;
				&::after{
					width: 40px;
					opacity: 1;
				}
			}
		}
		&__description {
			display: none;
		}
	}
	&__home-link{
		display: none;
	}
	@include min(1100px){
		&:not(.sticky &){
			.nav{
				position: relative;

				&__item:nth-child(2){
					margin-right: 77px;
				}
				&__item:nth-child(3){
					margin-left: 77px;
				}
			}
			.main-nav__home-link{
				position: absolute;
				display: inline-block;
				left: 50%;
				top: 0;
			}
		}
	}
	@include max($nav-horizontal-burger-threshold - 1px) {
		transition: max-height 0.3s ease-out;
		max-height: 0;
		overflow: hidden;
		padding: 0;
		&__item{
			animation: menuFadeOut 0.1s ease-out 0s forwards;
			opacity: 0;

			@for $li from 2 through 10 {
				$delay: (50 * ($li - 1));
				&:nth-of-type(#{$li}) {
					animation-delay: to-length($delay, "ms") !important;
				}
			}
		}

		.header__nav--toggled &{
			max-height: 20rem;

			&__item{
				animation: menuFadeIn 0.3s ease-out 0s forwards;
			}
		}
	}
}
