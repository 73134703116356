.list {
	list-style-type: none;
	padding: 0;
	margin: 0 0 1rem;

	.list {
		padding: 0 0 0 2em;
	}

	&__item {
		margin: 0 0 .7em;
	}

	&__description {
		margin-top: .25em;
	}
	.-chosen &{
		@include grid(600px 2 1.5rem, 1024px 4 3rem);
		margin: 0;
	}

	.board &{
		@include grid(600px 2 2px);
		@include min(600px){
			border: 2px solid #e4e4e4;
			background-color: #e4e4e4;
		}
	}
	.news &,
	.board &,
	.homepage-events &{
		@include minmax-precise(1px, 600px) {
			//scrolling list na mobilu
			display: flex;
			overflow-x: auto;
			overflow-y: hidden;
			scrollbar-width: thin;
			scroll-snap-type: x mandatory;
			scroll-padding-left: $page-margin;
			touch-action: manipulation;
			-webkit-overflow-scrolling: touch;
			margin-left: -$page-margin;
			margin-right: -$page-margin;
			padding-left: $page-margin;
			padding-right: $page-margin;
			padding-bottom: 20px; //kvůli scrollbaru
			li{
				flex-shrink: 0;
				width: 85%;
				scroll-snap-stop: always;
				scroll-snap-align: start;

				+ li {
					margin-left: 20px;
				}
			}
		}
	}
	.scrollslider-js > & {
		scrollbar-width: none;
		padding-bottom: 60px;
		margin-bottom: -40px;
	}
}

.org-contacts {
	&__contact {
		span:not(:first-of-type) {
			&::before {
				background-color: $color-secondary;
			}
		}
	}
}
