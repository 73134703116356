@font-face {
	font-family: 'OpenSans';
	src: url('/font/local/v-202411080924/OpenSans-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans';
	src: url('/font/local/v-202411080924/OpenSans-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'OpenSans';
	src: url('/font/local/v-202411080924/OpenSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans';
	src: url('/font/local/v-202411080924/OpenSans-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'OpenSans';
	src: url('/font/local/v-202411080924/OpenSans-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans';
	src: url('/font/local/v-202411080924/OpenSans-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'OpenSans';
	src: url('/font/local/v-202411080924/OpenSans-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans';
	src: url('/font/local/v-202411080924/OpenSans-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'OpenSans';
	src: url('/font/local/v-202411080924/OpenSans-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans';
	src: url('/font/local/v-202411080924/OpenSans-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'OpenSans';
	src: url('/font/local/v-202411080924/OpenSans-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans';
	src: url('/font/local/v-202411080924/OpenSans-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}
